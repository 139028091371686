<template>

  <div>
    <b-tabs>
      <b-tab title="未处理">
        <b-card
            no-body
            class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>显示</label>
                <v-select
                    v-model="limit"
                    :options="limitOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                />
                <label>条</label>

                <div class="demo-spacing-0" style="margin-left: 10px">
                  <b-alert variant="primary" show>
                    <div class="alert-body">
                      <span><strong>罚款总金额:{{ punishAmount }}</strong> </span>
                    </div>
                  </b-alert>
                </div>
              </b-col>

              <!-- 按钮 -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <!--搜索-->
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      class="mr-1"
                      @click="advanced_search"
                  >
                    <feather-icon
                        icon="SearchIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">高级搜索</span>
                  </b-button>
                  <!--处理罚款-->
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-success"
                      class="mr-1"
                      @click="showDealModal"
                  >
                    <feather-icon
                        icon="CheckCircleIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">处理罚款</span>
                  </b-button>
                  <!--清空勾选-->
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-danger"
                      class="mr-1"
                      @click="clearSelected"
                  >
                    <feather-icon
                        icon="Trash2Icon"
                        class="mr-50"
                    />
                    <span class="align-middle">清空勾选</span>
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="outline-success"
                      @click="exportExcel"
                      class="mr-1"
                  >
                    <feather-icon
                        icon="DownloadIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">导出</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

            <!-- 高级搜索 -->
            <b-card v-if="isShowCard">
              <b-form
                  @submit.prevent="searchByCondition"
                  @reset.prevent="resetCondition">
                <b-row>
                  <!--罚款单号-->
                  <b-col md="3">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="罚款单号"
                        label-for="punish_no"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="punish_no"
                          size="sm"
                          v-model="condition.punish_no"
                      />
                    </b-form-group>
                  </b-col>
                  <!--仓库名称-->
                  <b-col md="3">
                    <modal-select
                        label="仓库名称"
                        type="input"
                        v-on:change="fromChildren($event,['warehouse_name','warehouse_id'])"
                        :params="['warehouse_name','warehouse_id']"
                        modalName="仓库"
                        placeholder="点击选择仓库"
                        v-model="condition.warehouse_name"
                    >
                    </modal-select>
                  </b-col>
                  <!--店铺名称-->
                  <b-col md="3">
                    <modal-select
                        label="店铺名称"
                        type="input"
                        v-on:change="fromChildren($event,['store_name','store_id'])"
                        :params="['store_name','store_id']"
                        modalName="店铺"
                        placeholder="点击选择店铺"
                        v-model="condition.store_name"
                    >
                    </modal-select>
                  </b-col>
                  <!--平台-->
                  <b-col md="3">
                    <modal-select
                        label="平台"
                        type="input"
                        v-on:change="fromChildren($event,['company_name','company_id'])"
                        :params="['company_name','company_id']"
                        modalName="渠道方"
                        placeholder="点击选择平台"
                        v-model="condition.company_name"
                    >
                    </modal-select>
                  </b-col>
                  <!--扣款原因-->
                  <b-col md="3">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="扣款原因"
                        label-for="punish_reason"
                        label-size="sm"
                        class="mb-1"
                    >
                      <v-select
                          id="punish_reason"
                          :options="getCodeOptions('punish_reason')"
                          v-model="condition.punish_reason"
                          class="select-size-sm"
                          placeholder="请选择扣款原因"
                          :reduce="option => option.value"
                      />
                    </b-form-group>
                  </b-col>
                  <!--查询、重置按钮-->
                  <b-col
                      cols="12"
                      md="12"
                      class=" demo-inline-spacing d-flex align-items-center justify-content-end"
                  >
                    <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="submit"
                    >
                      <span class="align-right">查询</span>
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="reset"
                    >
                      <span class="align-right">重置</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card>

          </div>

          <b-table
              ref="refListTable"
              class="position-relative"
              :items="searchList"
              responsive
              hover
              small
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="orderBy"
              show-empty
              empty-text="未找到记录"
              :sort-desc.sync="isSortDirDesc"
              v-model="table"
          >

            <!--复选框-->
            <template v-slot:head(#)="data">
              <b-form-checkbox v-model="allchecked" @change="toggleAll" :indeterminate="indeterminate">
              </b-form-checkbox>
            </template>
            <template v-slot:cell(#)="row">
              <b-form-checkbox @change="checkboxClick(row.item)" v-model="isCheckedObj['index'+row.item.id]">
              </b-form-checkbox>
            </template>

            <!-- ID -->
            <template #cell(punish_id)="data">
              #{{ data.item.punish_id }}
            </template>

            <!-- 城市 -->
            <template #cell(city_id)="data">
              {{ data.item.province_name }}-{{ data.item.city_name }}
            </template>

            <!-- 发生日期 -->
            <template #cell(happen_date)="data">
              {{ toDate(data.item.happen_date) }}
            </template>

            <!-- 扣款原因 -->
            <template #cell(punish_reason)="data">
              {{ getCodeLabel('punish_reason', data.item.punish_reason) }}
            </template>

            <!-- 扣款方式 -->
            <template #cell(punish_method)="data">
              {{ getCodeLabel('punish_method', data.item.punish_method) }}
            </template>

            <!-- 罚款日期 -->
            <template #cell(punish_date)="data">
              {{ toDate(data.item.punish_date) }}
            </template>

            <!-- 备注 -->
            <template #cell(remark)="data">
              <div :id="`memo1-${data.item.id}`" style="width: 50px">
            <span v-if="data.item.remark">
              {{ data.item.remark.substring(0, 3) }}
              <span v-if="data.item.remark.length>3">...</span>
            </span>
              </div>
              <b-tooltip :target="`memo1-${data.item.id}`" placement="top">
                {{ data.item.remark }}
              </b-tooltip>
            </template>

            <!-- 罚款单附件 -->
            <template #cell(attachments1)="data">
              <attachment-upload :theme="'preview-dropdown'"
                                 :id="data.item.attachments1"
                                 :readonly="true"
              />
            </template>

            <!-- 申诉中附件 -->
            <template #cell(attachments2)="data">
              <attachment-upload v-if="data.item.attachments2 != null"
                                 :theme="'preview-dropdown'"
                                 :id="data.item.attachments2"
                                 :readonly="true"
              />
            </template>

            <!-- 已处理附件 -->
            <template #cell(attachments3)="data">
              <attachment-upload v-if="data.item.attachments3 != null"
                                 :theme="'preview-dropdown'"
                                 :id="data.item.attachments3"
                                 :readonly="true"
              />
            </template>

            <!-- 罚款单处理结果 -->
            <template #cell(deal_result)="data">
              {{ getCodeLabel('punish_deal_result', data.item.deal_result) }}
            </template>

            <!-- 处理结果备注 -->
            <template #cell(deal_remark)="data">
              <div :id="`memo2-${data.item.id}`" style="width: 50px">
            <span v-if="data.item.deal_remark">
              {{ data.item.deal_remark.substring(0, 3) }}
              <span v-if="data.item.deal_remark.length>3">...</span>
            </span>
              </div>
              <b-tooltip :target="`memo2-${data.item.id}`" placement="top">
                {{ data.item.deal_remark }}
              </b-tooltip>
            </template>

            <!--添加时间-->
            <template #cell(create_time)="data">
              {{ toTime(data.item.create_time) }}
            </template>

            <!--添加人-->
            <template #cell(creator)="{value}">
              {{ getCodeLabel('user', value) }}
            </template>

            <!--更新时间-->
            <template #cell(update_time)="data">
              {{ toTime(data.item.update_time) }}
            </template>

            <!--更新人-->
            <template #cell(updator)="{value}">
              {{ getCodeLabel('user', value) }}
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                    v-model="start"
                    :total-rows="listTotals"
                    :per-page="limit"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>
      </b-tab>

      <b-tab title="已处理" @click="childRefetchData">
        <punish-order-dealt-list ref="dealtList"></punish-order-dealt-list>
      </b-tab>
    </b-tabs>


    <!--modal-->
    <b-modal
        id="dealModal"
        ok-title="确认"
        cancel-title="取消"
        @ok="dealPunishOrder"
        centered
        size="lg"
        title=处理罚款
    >
      <b-row>
        <!--罚款总金额-->
        <b-col md="8">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="罚款金额"
              label-for="total_amount"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="total_amount"
                size="sm"
                readonly
                v-model="totalAmount"
            />
          </b-form-group>
        </b-col>
        <!--收款金额-->
        <b-col md="8">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="收款金额"
              label-for="total_receive"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="total_receive"
                size="sm"
                type="number"
                v-model="punishorderdeal.total_receive"
            />
          </b-form-group>
        </b-col>
        <!--服务商-->
        <b-col md="8">
          <modal-select
              label="服务商 *"
              type="input"
              v-on:change="setCompany($event)"
              :params="['company_name','company_id']"
              modalName="承运商"
              placeholder="点击选择服务商"
              v-model="punishorderdeal.company_name"
          >
          </modal-select>
        </b-col>
        <!--备注-->
        <b-col md="12">
          <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="处理结果备注"
              label-for="remark"
              label-size="sm"
              class="mb-1"
          >
            <b-form-textarea
                id="remark"
                size="sm"
                v-model="punishorderdeal.remark"
                rows="3"
                max-rows="6"
            />
          </b-form-group>
        </b-col>
        <!--附件-->
        <b-col md="12">
          <b-form-group
              label-cols="2"
              label-cols-lg="2"
              label="相关附件"
              label-for="attachments"
              label-size="sm"
              :class="'mb-1'"
          >
            <attachment-upload :theme="'files'"
                               attachment_id="attachments"
                               :id="punishorderdeal.attachments"
                               object_type="punishorder"
                               :object_id="punishorderdeal.id"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>
      </b-row>

    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTabs, BTab,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import punishorderdealUseList from './punishorderdealUseList'
import punishorderStore from '../punishorder/punishorderStore'
import Ripple from "vue-ripple-directive";
import ModalSelect from "@/views/components/modal/ModalSelect";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import AppTimeline from "@core/components/app-timeline/AppTimeline";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem";
import {getUserData} from "@/auth/utils";
import {useToast} from "vue-toastification/composition";
import punishorderdealStore from "@/views/apps/punishorderdeal/punishorderdealStore";
import punishOrderDealtList from "@/views/apps/punishorderdeal/punishorderdealt/PunishOrderDealtList";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalSelect,
    AttachmentUpload,
    AppTimeline,
    AppTimelineItem,
    BTabs,
    BTab,
    punishOrderDealtList,
  },
  directives: {
    Ripple,
  },
  watch: {
    checked: {
      handler(newVal, oldVal) {
        let show = 0;
        const tableIdArray = []
        this.table.forEach((value) => {
          tableIdArray.push(value.id)
        })
        this.checked.forEach((value) => {
          if (tableIdArray.indexOf(Number(value.id)) > -1) {
            show += 1;
          }
        })
        if (this.table.length === show && show > 0) {
          this.indeterminate = false
          this.allchecked = true
        } else if (show === 0) {
          this.indeterminate = false
          this.allchecked = false
        } else {
          this.indeterminate = true
          this.allchecked = false
        }
        this.$forceUpdate()
      },
      deep: true//深度
    },
    table: {
      handler(newVal, oldVal) {
        let show = 0;
        const tableIdArray = []
        this.table.forEach((value) => {
          tableIdArray.push(value.id)
        })
        this.checked.forEach((value) => {
          if (tableIdArray.indexOf(Number(value.id)) > -1) {
            show += 1;
          }
        })
        if (this.table.length === show && show > 0) {
          this.indeterminate = false
          this.allchecked = true
        } else if (show === 0) {
          this.indeterminate = false
          this.allchecked = false
        } else {
          this.indeterminate = true
          this.allchecked = false
        }
        this.$forceUpdate()
      },
      deep: true//深度
    },
  },
  data() {
    return {
      isShowCard: false,
      rangeConfig: {
        mode: 'range',
        maxDate: 'today',
      },
      allchecked: false,
      indeterminate: false,
      isCheckedObj: {},
      table: [],
      checked: [],
      checkedIds: [],
      totalAmount: 0,
      punishorderdeal: {},
    }
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('punishorder')) store.registerModule('punishorder', punishorderStore)
    if (!store.hasModule('punishorderdeal')) store.registerModule('punishorderdeal', punishorderdealStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('punishorder')) store.unregisterModule('punishorder')
      if (store.hasModule('punishorderdeal')) store.unregisterModule('punishorderdeal')
    })

    const advanced_search = function () {
      this.isShowCard = !this.isShowCard
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const setCompany = function (params) {
      this.punishorderdeal.company_name = params.company_name
      this.punishorderdeal.company_id = params.company_id
      this.$forceUpdate()
    }

    const searchByCondition = function () {
      refetchData()
    }

    const resetCondition = function () {
      this.condition = {}
      refetchData()
    }

    // 点击全选按钮
    const toggleAll = function (checked) {
      let checkedIds = [];
      this.checked.forEach(item => {
        checkedIds.push(Number(item.id))
      })
      this.table.forEach(element => {
        if (checked) {
          if (checkedIds.indexOf(element.id) === -1) {
            this.checked.push(element);
          }
        } else {
          //取消勾选
          for (let i = 0; i < this.checked.length; i++) {
            //在已勾选数组中找出要取消勾选的
            if (this.checked[i].id == element.id) {
              this.checked.splice(i, 1)
            }
          }
        }
        this.isCheckedObj['index' + element.id] = checked;
      });
      this.$forceUpdate()
    }
    // 复选框点击选中与取消
    const checkboxClick = function (item) {
      if (this.isCheckedObj['index' + item.id]) {
        //勾选
        this.checked.push(item)
      } else {
        //取消勾选
        for (let i = 0; i < this.checked.length; i++) {
          //在已勾选数组中找出要取消勾选的
          if (this.checked[i].id == item.id) {
            this.checked.splice(i, 1)
          }
        }
      }
      this.$forceUpdate()
    }
    //清空勾选
    const clearSelected = function () {
      this.checked.forEach(item => {
        this.isCheckedObj['index' + item.id] = false;
      })
      this.checked = []
    }

    //初始化模态框
    const initDealModal = function () {
      //清空原来的
      this.totalAmount = 0
      this.checkedIds = []
      this.checked.forEach(item => {
        this.totalAmount += item.amount
        this.checkedIds.push(item.id)
      })
      this.punishorderdeal = {}
    }

    const showDealModal = function () {
      if (this.checked.length < 1) {
        toast.error('请选择至少一条罚单')
        return false
      }
      for (let i = 0; i < this.checked.length; i++) {
        //单据类型不同
        if (this.checked[0].warehouse_id !== this.checked[i].warehouse_id) {
          toast.error('不同仓库不能一起收款')
          return false
        }
      }
      this.initDealModal()
      this.$bvModal.show('dealModal')
    }

    const dealPunishOrder = function () {
      if (isEmpty(this.punishorderdeal.total_receive)) {
        toast.error('请填写 实收金额')
        return false
      }
      if (isEmpty(this.punishorderdeal.company_name)) {
        toast.error('请选择 服务商')
        return false
      }
      if (this.punishorderdeal.total_receive < 0) {
        toast.error('实收金额不能为负')
        return false
      }
      this.punishorderdeal.total_amount = this.totalAmount
      this.punishorderdeal.punish_ids = this.checkedIds.toString()
      store.dispatch('punishorderdeal/dealPunishOrder', this.punishorderdeal).then(res => {
        if (res.data.code == 0) {
          this.$swal({
            icon: 'success',
            title: res.data.data,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          //清空勾选
          this.clearSelected()
          refetchData()
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const onUploaded = function (id, attachment, result) {
      this.punishorderdeal[id] = result
    }

    const childRefetchData = function () {
      this.$refs.dealtList.refetchData()
    }

    const exportExcel = function () {
      const params = {
        punish_no:condition.value.punish_no,
        warehouse_id:condition.value.warehouse_id,
        company_id:condition.value.company_id,
        store_id:condition.value.store_id,
        punish_reason:condition.value.punish_reason,
        status:3,//运营已处理的
      }
      store.dispatch('punishorderdeal/exportExcel', params).then(res => {
        toast.success('导出成功')
        let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = '罚款未处理' + new Date().getTime()
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data)
        link.setAttribute('download', filename + '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })

    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,
      punishAmount,

      // UI
    } = punishorderdealUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      advanced_search,
      fromChildren,
      setCompany,
      searchByCondition,
      resetCondition,
      condition,
      punishAmount,
      toggleAll,
      checkboxClick,
      dealPunishOrder,
      clearSelected,
      initDealModal,
      showDealModal,
      onUploaded,
      childRefetchData,
      exportExcel,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
    }
  },
  created() {
    this.condition.punish_id = this.$route.query.id || 0;
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
