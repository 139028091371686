import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function punishorderUseList(props) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)
  const punishAmount = ref(0)

  // Table Handlers
  const tableColumns = [
    //{ key: 'punish_id', label: 'ID', sortable: true },
    { key: 'punish_no', label: '罚款单号', sortable: true },
    { key: 'warehouse_name', label: '仓库名称', sortable: true },
    { key: 'store_name', label: '店铺', sortable: true },
    { key: 'company_name', label: '平台', sortable: true },
    { key: 'city_id', label: '城市' , sortable: true},
    { key: 'business_name', label: '运营人', sortable: true },
    { key: 'happen_date', label: '发生日期', sortable: true },
    { key: 'out_product_name', label: '罚款产品', sortable: true },
    { key: 'punish_reason', label: '罚款原因', sortable: true },
    { key: 'amount', label: '罚款金额', sortable: true },
    { key: 'punish_method', label: '扣款方式', sortable: true },
    { key: 'punish_date', label: '罚款日期', sortable: true },
    { key: 'responsibility_name', label: '当前责任人', sortable: true },
    { key: 'remark', label: '备注', sortable: true },
    { key: 'attachments1', label: '罚款单附件', sortable: true },
    { key: 'attachments2', label: '申诉中附件', sortable: true },
    { key: 'attachments3', label: '已处理附件', sortable: true },
    { key: 'status', label: '罚款单处理进度', sortable: true },
    { key: 'deal_result', label: '罚款单处理结果', sortable: true },
    { key: 'deal_remark', label: '处理结果备注', sortable: true },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('punish_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('punishorder/search', {
        deal_id:props.deal_id,
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        punishAmount.value = data.ext.punishAmount

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    refetchData,
    punishAmount,

    // Extra Filters
  }
}
