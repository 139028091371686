<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        class="mb-0"
    >
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >


        <!-- 城市 -->
        <template #cell(city_id)="data">
          {{ data.item.province_name }}-{{ data.item.city_name }}
        </template>

        <!-- 发生日期 -->
        <template #cell(happen_date)="data">
          {{ toDate(data.item.happen_date) }}
        </template>

        <!-- 扣款原因 -->
        <template #cell(punish_reason)="data">
          {{ getCodeLabel('punish_reason', data.item.punish_reason) }}
        </template>

        <!-- 扣款方式 -->
        <template #cell(punish_method)="data">
          {{ getCodeLabel('punish_method', data.item.punish_method) }}
        </template>

        <!-- 罚款日期 -->
        <template #cell(punish_date)="data">
          {{ toDate(data.item.punish_date) }}
        </template>

        <!-- 备注 -->
        <template #cell(remark)="data">
          <div :id="`memo1-${data.item.id}`" style="width: 55px">
            <span v-if="data.item.remark">
              {{ data.item.remark.substring(0, 3) }}
              <span v-if="data.item.remark.length>3">...</span>
            </span>
          </div>
          <b-tooltip :target="`memo1-${data.item.id}`" placement="top">
            {{ data.item.remark }}
          </b-tooltip>
        </template>

        <!-- 罚款单附件 -->
        <template #cell(attachments1)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :id="data.item.attachments1"
                             :readonly="true"
          />
        </template>

        <!-- 申诉中附件 -->
        <template #cell(attachments2)="data">
          <attachment-upload v-if="data.item.attachments2 != null"
                             :theme="'preview-dropdown'"
                             :id="data.item.attachments2"
                             :readonly="true"
          />
        </template>

        <!-- 已处理附件 -->
        <template #cell(attachments3)="data">
          <attachment-upload v-if="data.item.attachments3 != null"
                             :theme="'preview-dropdown'"
                             :id="data.item.attachments3"
                             :readonly="true"
          />
        </template>

        <!-- 罚款单处理进度 -->
        <template #cell(status)="data">
          {{ getCodeLabel('punish_status', data.item.status) }}
        </template>

        <!-- 罚款单处理结果 -->
        <template #cell(deal_result)="data">
          {{ getCodeLabel('punish_deal_result', data.item.deal_result) }}
        </template>

        <!-- 处理结果备注 -->
        <template #cell(deal_remark)="data">
          <div :id="`memo2-${data.item.id}`" style="width: 55px">
            <span v-if="data.item.deal_remark">
              {{ data.item.deal_remark.substring(0, 3) }}
              <span v-if="data.item.deal_remark.length>3">...</span>
            </span>
          </div>
          <b-tooltip :target="`memo2-${data.item.id}`" placement="top">
            {{ data.item.deal_remark }}
          </b-tooltip>
        </template>

        <!--添加时间-->
        <template #cell(create_time)="data">
          {{ toTime(data.item.create_time) }}
        </template>

        <!--添加人-->
        <template #cell(creator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!--更新时间-->
        <template #cell(update_time)="data">
          {{ toTime(data.item.update_time) }}
        </template>

        <!--更新人-->
        <template #cell(updator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>


      </b-table>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import punishorderUseList from './punishorderDetailUseList'
import punishorderStore from './punishorderStore'
import Ripple from "vue-ripple-directive";
import ModalSelect from "@/views/components/modal/ModalSelect";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import AppTimeline from "@core/components/app-timeline/AppTimeline";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem";
import {getUserData} from "@/auth/utils";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AttachmentUpload,
  },
  props: {
    deal_id: {
      type: Number,
      default: 0
    },
  },
  setup(props) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('punishorder')) store.registerModule('punishorder', punishorderStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('punishorder')) store.unregisterModule('punishorder')
    })

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      punishAmount,

      // UI
    } = punishorderUseList(props)

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      punishAmount,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
