import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function punishorderdealUseList() {
  // Use toast
  const toast = useToast()
  const condition = ref({})
  const refListTable = ref(null)
  const punishAmount = ref(0)
  const receiveAmount = ref(0)

  // Table Handlers
  const tableColumns = [
    { key: 'deal_id', label: 'ID', sortable: true },
    { key: 'deal_no', label: '处理单号', sortable: true },
    { key: 'total_amount', label: '罚款总金额', sortable: true },
    { key: 'total_receive', label: '收款总金额', sortable: true },
    { key: 'company_name', label: '服务商名称', sortable: true },
    { key: 'remark', label: '备注', sortable: true },
    { key: 'attachments', label: '相关附件', sortable: true },
    { key: 'create_time', label: '处理罚款时间', sortable: true },
    { key: 'creator', label: '罚款处理人', sortable: true },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('deal_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('punishorderdeal/search', {
        deal_no:condition.value.deal_no,
        company_id:condition.value.company_id,//服务商名称
        punish_no:condition.value.punish_no,
        warehouse_id:condition.value.warehouse_id,
        company_id2:condition.value.company_id2,//平台名称
        store_id:condition.value.store_id,
        punish_reason:condition.value.punish_reason,
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        punishAmount.value = data.ext.punishAmount
        receiveAmount.value = data.ext.receiveAmount

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    condition,
    refetchData,
    punishAmount,
    receiveAmount,

    // Extra Filters
  }
}
