import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from "@/libs/axios";

export default function punishorderUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)
  const condition = ref({})
  const punishAmount = ref(0)

  // Table Handlers
  const tableColumns = [
    { key: '#', label: ''},
    { key: 'punish_id', label: 'ID', sortable: true },
    { key: 'punish_no', label: '罚款单号', sortable: true },
    { key: 'warehouse_name', label: '仓库名称', sortable: true },
    { key: 'store_name', label: '店铺', sortable: true },
    { key: 'company_name', label: '平台', sortable: true },
    { key: 'city_id', label: '城市' , sortable: true},
    { key: 'business_name', label: '运营人', sortable: true },
    { key: 'happen_date', label: '发生日期', sortable: true },
    { key: 'responsibility_name', label: '当前责任人', sortable: true },
    { key: 'out_product_name', label: '罚款产品', sortable: true },
    { key: 'punish_reason', label: '罚款原因', sortable: true },
    { key: 'amount', label: '罚款金额', sortable: true },
    { key: 'punish_method', label: '扣款方式', sortable: true },
    { key: 'punish_date', label: '罚款日期', sortable: true },
    { key: 'remark', label: '备注', sortable: true },
    { key: 'attachments1', label: '罚款单附件', sortable: true },
    { key: 'attachments2', label: '申诉中附件', sortable: true },
    { key: 'attachments3', label: '已处理附件', sortable: true },
    { key: 'deal_result', label: '罚款单处理结果', sortable: true },
    { key: 'deal_remark', label: '处理结果备注', sortable: true },
    { key: 'create_time', label: '添加时间', sortable: true },
    { key: 'creator', label: '添加人', sortable: true },
    { key: 'update_time', label: '更新时间', sortable: true },
    { key: 'updator', label: '更新人', sortable: true },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('punish_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    axios.post('/api/punishorder/search', {
          punish_no:condition.value.punish_no,
          warehouse_id:condition.value.warehouse_id,
          company_id:condition.value.company_id,
          store_id:condition.value.store_id,
          punish_reason:condition.value.punish_reason,
          status:3,//运营已处理的
          search_key: searchKey.value,
          start: start.value,
          limit: limit.value,
          order_by: orderBy.value,
          order_desc: isSortDirDesc.value === true ? 'desc':'',
        })
        .then(response => {
          const data = response.data.data
          const list = data.ext.list
          listTotals.value = data.ext.totals
          punishAmount.value = data.ext.punishAmount

          callback(list)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    refetchData,
    condition,
    punishAmount,

    // Extra Filters
  }
}
